<template>
  <div class="hello">
    <div ref="canvas" id="canvas" />
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
// import { OimoPhysics } from 'three/examples/jsm/physics/OimoPhysics.js';
// import { XacroLoader } from 'xacro-parser';
// import URDFLoader from 'urdf-loader';
// import axios from '../lib/axios';
import ROSLIB from 'roslib';
export default {
  name: 'example01',
  props: {},
  data () {
    const scene = new THREE.Scene()
    // const composer = new THREE.EffectComposer(new WebGLRenderer())
    // const effectPass = new THREE.EffectPass(camera, new BloomEffect())
    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    )
    camera.position.y = 10;
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.shadowMap.enabled = true;
    // renderer.outputEncoding = THREE.PCFSoftShadowMap;


    const light = new THREE.DirectionalLight(0xffffff, 0.7)
		// light.color.setHSL( 1, 1, 1 );
		light.position.set( 1, 5.75, -0.2 );
		light.position.multiplyScalar( 10 );
		light.castShadow = true;
    light.shadow.mapSize.width = 2048;
		light.shadow.mapSize.height = 2048;

		const d = 50;

		light.shadow.camera.left = - d;
		light.shadow.camera.right = d;
		light.shadow.camera.top = d;
		light.shadow.camera.bottom = - d;

		light.shadow.camera.far = 3500;
		light.shadow.bias = - 0.0001;

    const axes = new THREE.AxesHelper(5)

    return {
      scene: scene,
      camera: camera,
      controls: [],
      objects: [],
      renderer: renderer,
      light: light,
      // cube: cube,
      axes: axes,
      speed: 0.01,
      translation: {},
      rotation: {},
      animationsObjects: [],
      physics: null
    };
  },
  created () {
    console.log('created')
    this.scene.add(this.camera)
    this.scene.add(this.light)
    const dirLightHelper = new THREE.DirectionalLightHelper( this.light, 1 );
    this.scene.add( dirLightHelper );

    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.3 );
    // hemiLight.color.setHSL( 0, 0, 0.5 );
    // hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
    hemiLight.position.set( 0, 25, 0 );
    this.scene.add( hemiLight );

    const hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 1 );
    this.scene.add( hemiLightHelper );
    // const ambientLight = new THREE.AmbientLight( 0xffffff, 0.5 );
    // this.scene.add( ambientLight );

    // this.scene.add(this.cube)
    // this.scene.add(this.axes)
    const grid = new THREE.GridHelper( 15, 15, 0x888888, 0x444444 );
    grid.position.set(2.5, 0, -3)
    this.scene.add(grid);
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    // this.light.position.set(0, 5, 5)
    this.camera.position.z = 5
    this.scene.background = new THREE.Color(0xffffff);

    // setTimeout(this.updateLocation, 1000);
  },

  async mounted() {
    console.log('mounted')
    this.$refs.canvas.appendChild(this.renderer.domElement)
		this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = 1.0
    this.controls.zoomSpeed = 1
    this.controls.panSpeed = 1
    this.controls.target.y = 0.5;
    this.controls.update();
    // this.physics = await OimoPhysics();

    this.newfloor();
    this.animate();
    this.addRobot();
    this.addDesk();
    this.addWall2();
    this.addCube();
    // this.addWall(2, 1, 0.1, 0.3, 0.5, 1);
    // this.addWall(0.1, 1, 7, -0.7, 0.5, -2.5);
    this.connectRos();
  },
  methods: {
    connectRos () {

      var ros = new ROSLIB.Ros({
        url : 'ws://192.168.1.196:9090'
      });
      ros.on('connection', function() {
        console.log('Connected to websocket server.');
      });
      var tfClient = new ROSLIB.TFClient({
        ros : ros,
        fixedFrame : 'map',
        angularThres : 0.01,
        transThres : 0.01
      });

      tfClient.subscribe('TE2124008/base_footprint',this.tfHandler);
    },
    animationFinish(){
      if (!this.objects) return;
      const quaternion = new THREE.Quaternion(this.rotation.x, this.rotation.z, this.rotation.y, this.rotation.w);
      const position = new THREE.Vector3(this.translation.x, this.translation.z, this.translation.y *-1 );
      this.objects[0].quaternion.copy( quaternion );
      this.objects[0].position.copy( position );
      // console.log(position, quaternion)
    },
    tfHandler(tf){
      // console.log(tf.translation);
      this.translation = tf.translation;
      this.rotation = tf.rotation;
      const quaternion = new THREE.Quaternion(tf.rotation.x, tf.rotation.z, tf.rotation.y, tf.rotation.w);
      this.objects[0].quaternion.copy( quaternion );
      let position = new THREE.Vector3();
      position.set( tf.translation.x, tf.translation.z, tf.translation.y *-1 );
      this.objects[0].position.copy( position );
      // console.log(tf, this.objects[0].userData.mixer)
      if (!this.objects[0].userData.mixer) {


        this.objects[0].userData.mixer = new THREE.AnimationMixer( this.objects[0] );
        this.objects[0].userData.clock = new THREE.Clock();
        this.objects[0].userData.mixer.addEventListener( 'finished', this.animationFinish);
      }




      // this.objects[0].userData.mixer.stopAllAction();

      let track = new THREE.VectorKeyframeTrack(
        '.position',
        [0, 1],
        [
          this.objects[0].position.x,
          this.objects[0].position.y,
          this.objects[0].position.z,
          this.translation.x,
          this.translation.z,
          this.translation.y * -1,
        ]
      );

      let track2 = new THREE.QuaternionKeyframeTrack(
        '.quaternion',
        [0, 1],
        [
          this.objects[0].quaternion.x,
          this.objects[0].quaternion.y,
          this.objects[0].quaternion.z,
          this.objects[0].quaternion.w,

          this.rotation.x,
          this.rotation.z,
          this.rotation.y,
          this.rotation.w,
        ]
      );

      const animationClip = new THREE.AnimationClip(null, 0.5, [track, track2]);
      const animationAction = this.objects[0].userData.mixer.clipAction(animationClip);

      // animationAction.stop();
      animationAction.setLoop(THREE.LoopOnce);
      animationAction.setDuration(0.5);
      // animationAction.play();

    },
    newfloor() {
      // let texture = new THREE.TextureLoader().load('/map/202112151603dd710.png');
      // let texture = new THREE.TextureLoader().load('');
      // texture.wrapS = THREE.RepeatWrapping;
      // texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set( 1, 1 );
      const material = new THREE.MeshPhongMaterial( { color: '#222222' } );
      // texture.mapping = THREE.UVMapping;
      // let material = new THREE.MeshBasicMaterial({
      //   side: THREE.DoubleSide,
      //   // map: texture,
      //   transparent: true
      // });
      // 500 489 0.050000
      let width = 465;
      let height = 477;
      let resolution = 0.05;
      let originX = -6.5322;
      let originY = -10.463;
      let geometry = new THREE.BoxGeometry( width * resolution , 0.0001, height * resolution);
      const plane = new THREE.Mesh( geometry, material );

      // plane.rotation.x = - Math.PI / 2.0;
      plane.position.x = originX + (width * resolution / 2);
      plane.position.z = (originY + (height * resolution / 2)) * -1;
      plane.position.y = -0.0001;
      plane.castShadow = true;
      plane.receiveShadow = true;
      this.scene.add( plane );
      // this.physics.addMesh( plane, 0);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.camera.updateMatrixWorld();
      // if (this.objects[0]) this.objects[0].rotation.z += this.speed;
      // console.log(this.objects[0])

      // if (this.objects[0] && this.objects[0].position.x < this.translation.x) {
      //   this.objects[0].position.x += 0.02
      // }
      // if (this.objects[0] && this.objects[0].position.x > this.translation.x) {
      //   this.objects[0].position.x -= 0.02
      // }
      //
      // if (this.objects[0] && this.objects[0].position.z < this.translation.y * -1) {
      //   this.objects[0].position.z += 0.02
      // }
      // if (this.objects[0] && this.objects[0].position.z > this.translation.y * -1) {
      //   this.objects[0].position.z -= 0.02
      // }

      // if (this.objects[0]) this.objects[0].position.x = this.translation.x;
      // if (this.objects[0]) this.objects[0].position.z = this.translation.y * -1;

      // if (this.objects[0]) this.objects[0].rotation.x = -Math.PI / 2.0;
      // const quaternion = new THREE.Quaternion(this.rotation.x, this.rotation.y, this.rotation.z, this.rotation.w);
      // this.objects[0].quaternion.copy( quaternion );
      // if (this.objects[0]) this.objects[0].rotation.x = -Math.PI / 2.0;


      this.controls.update();
			this.renderer.render(this.scene, this.camera);
      this.animationsObjects.forEach(mesh => {
        if (mesh.userData.clock && mesh.userData.mixer) {
          mesh.userData.mixer.update(mesh.userData.clock.getDelta());
        }
      });


    },
    SheenChair () {
      // new GLTFLoader()
      //   .setPath( '/gltf/' )
      //   .load( 'SheenChair.glb', gltf => {
      //
      //     gltf.scene.position.z = -2;
      //     gltf.scene.position.x = 1;
      //     gltf.scene.rotation.y = Math.PI / 2.0;
      //     gltf.scene.castShadow = true;
			// 		gltf.scene.receiveShadow = true;
      //     this.scene.add( gltf.scene );
      //     // const object = gltf.scene.getObjectByName( 'SheenChair_fabric' );
      //     //
      //     // const gui = new GUI();
      //     //
      //     // gui.add( object.material, 'sheen', 0, 1 );
      //     // gui.open();
      //
      //   } );
      new GLTFLoader()
        .load( '/gltf/table/model.glb', gltf => {

          gltf.scene.position.z = -2.8;
          gltf.scene.position.x = 2.2;
          gltf.scene.rotation.x = -Math.PI / 2.0;
          // gltf.scene.rotation.z = -Math.PI / 1.0;
          gltf.scene.castShadow = true;
					gltf.scene.receiveShadow = true;
          gltf.scene.scale.set(0.03, 0.03, 0.03)
          this.scene.add( gltf.scene );

          // this.physics.addMesh( gltf.scene.children[0], 1 );
          // var box = new THREE.Box3().setFromObject( gltf.scene);
          // const material = new THREE.MeshPhongMaterial( { color: 0xff5533, specular: 0x111111, shininess: 200 } );
					// const mesh = new THREE.Mesh( gltf, material );
          // this.scene.add( mesh );
          // var size = box.getSize(new THREE.Vector3());
          // console.log(size);
          // const object = gltf.scene.getObjectByName( 'SheenChair_fabric' );
          //
          // const gui = new GUI();
          //
          // gui.add( object.material, 'sheen', 0, 1 );
          // gui.open();

        } );
      new GLTFLoader()
        .load( '/gltf/table/model.glb', gltf => {

          gltf.scene.position.z = -1.7;
          gltf.scene.position.x = 4.6;
          gltf.scene.rotation.x = -Math.PI / 2.0;
          gltf.scene.rotation.z = -Math.PI / 1.0;
          gltf.scene.castShadow = true;
					gltf.scene.receiveShadow = true;
          gltf.scene.scale.set(0.03, 0.03, 0.03)
          this.scene.add( gltf.scene );

          // var box = new THREE.Box3().setFromObject( gltf.scene);

          // var size = box.getSize(new THREE.Vector3());

          // const object = gltf.scene.getObjectByName( 'SheenChair_fabric' );
          //
          // const gui = new GUI();
          //
          // gui.add( object.material, 'sheen', 0, 1 );
          // gui.open();

        } );

    },
    addRobot() {
      const loader = new OBJLoader();
      loader.load('/model/obj/tetra.obj',
        (object) => {
          object.traverseVisible((obj) => {
            obj.castShadow = true;
            // obj.receiveShadow = true;
          })
          // object.scale.set(0.001, 0.001, 0.001)
          let position = new THREE.Vector3();
          position.set( 0, 0, 0 );
          object.position.copy(position);
          this.scene.add( object );
          this.objects.push(object)
          this.animationsObjects.push(object)
        },
        // called when loading is in progresses
        function ( xhr ) {
          console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        // called when loading has errors
        function ( error ) {
          console.log( 'An error happened', error );
        }
      );
      // new URDFLoader()
      //   .load( '/urdf/tetraDS/model.urdf', gltf => {
      //     // console.log(gltf)
      //     gltf.position.z = -4;
      //     gltf.position.x = 1;
      //     gltf.rotation.x = -Math.PI / 2.0;
      //     gltf.rotation.z = -Math.PI / 1.5;
      //
      //     this.scene.add( gltf );
      //     this.objects.push(gltf);
      //     let position = new THREE.Vector3();
      //     position.set( -1, -4, -2.5 );
      //     // this.physics.addMesh( gltf, 1 );
      //     // this.physics.setMeshPosition(gltf, position, 0)
      //     // this.objects.push(robot);
      //     // this.objects[0].userData.mixer = new THREE.AnimationMixer( this.objects[0] );
      //     // const animationClip = new THREE.AnimationClip(null, 1, []);
      //     // const animationAction = this.objects[0].userData.mixer.clipAction(animationClip);
      //     //
      //     // animationAction.setLoop(THREE.LoopOnce);
      //     // animationAction.setDuration(0.8);
      //     // animationAction.play();
      //     // this.objects[0].userData.clock = new THREE.Clock();
      //     // // mesh.userData.clock = new Clock();
      //     //
      //     // this.animationsObjects.push(this.objects[0]);
      //
      //   } );


      // const url = '/model/tetraDS/tetraDS.xacro';
      // const xacroLoader = new XacroLoader();
      // xacroLoader.load(url, xml => {
      //
      //   const urdfLoader = new URDFLoader();
      //   urdfLoader.workingPath = THREE.LoaderUtils.extractUrlBase( url );
      //   // console.log(urdfLoader.workingPath, xml)
      //   const robot = urdfLoader.parse( xml );
      //   console.log(robot);
      //   robot.position.z = -4;
      //   robot.position.x = 1;
      //   robot.rotation.x = -Math.PI / 2.0;
      //   robot.rotation.z = -Math.PI / 1.5;
      //   this.scene.add( robot );
      //
      //   // this.objects.push(robot);
      // }, err => {
      //   console.log(err);
      // });
    },
    addWall2() {
      const loader = new OBJLoader();
      loader.load('/model/obj/wall.obj',
        (object) => {
          // console.log(object)
          object.traverseVisible((obj) => {
            obj.castShadow = true;
            // obj.receiveShadow = true;
          })
          // object.scale.set(0.001, 0.001, 0.001)
          let position = new THREE.Vector3();
          position.set( 3.4, 0, -2 );
          object.position.copy(position);
          object.rotation.y = Math.PI / 2.0;
          this.scene.add( object );
        },
        // called when loading is in progresses
        function ( xhr ) {
          console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        // called when loading has errors
        function ( error ) {
          console.log( 'An error happened', error );
        }
      )
    },
    addDesk() {
      const loader = new OBJLoader();
      loader.load('/model/obj/desk.obj',
        (object) => {
          object.traverseVisible((obj) => {
            obj.castShadow = true;
            // obj.receiveShadow = true;
          })
          // object.scale.set(0.001, 0.001, 0.001)
          let position = new THREE.Vector3();
          position.set( 2.8, 0, -2.6 );
          object.position.copy(position);
          // object.rotation.y = -Math.PI / 2.0;
          this.scene.add( object );
        },
        // called when loading is in progresses
        function ( xhr ) {
          console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        // called when loading has errors
        function ( error ) {
          console.log( 'An error happened', error );
        }
      )
    },
    addWall(x, y, z, p_x, p_y, p_z) {
      const material = new THREE.MeshStandardMaterial({
        side: THREE.FrontSide,
        color: 'hsl(95%, 95%, 95%)',
        wireframe: false
      });
      const geometry = new THREE.BoxGeometry(x, y, z)
      const wall = new THREE.Mesh(geometry, material, 1);
      let position = new THREE.Vector3();
      // console.log(wall, )
      position.set( p_x, p_y, p_z );
      wall.position.copy(position);
      this.scene.add(wall);
      // this.physics.addMesh( wall, 1 );
      // this.physics.setMeshPosition(wall, position, 0)
    },
    addCube() {
      const material = new THREE.MeshStandardMaterial({
          side: THREE.FrontSide,
          color: 'hsl(95%, 65%, 65%)',
          wireframe: false
      });

      const cube = new THREE.Mesh(new THREE.BoxGeometry(0.35, 0.3, 0.6), material);
      let position = new THREE.Vector3();
      position.set( 2.6, 0.15, -0.5 );
      cube.position.copy(position)
      this.scene.add(cube);

      const cube2 = new THREE.Mesh(new THREE.BoxGeometry(0.35, 0.3, 0.6), material);
      let position2 = new THREE.Vector3();
      position2.set( 3.6, 0.15, 0.5 );
      cube2.position.copy(position2)
      this.scene.add(cube2);

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
